import {useEffect, useState} from "react";

const DedicatedNumbersShowNumber = ({record}:any)=>{
    const [number, setNumber] = useState("")

    useEffect(() => {
        if(record){
            if(record.office.dedicatedChannel){
                setNumber(record.office.dedicatedChannel.name)
            }
            else if(record.channel){
                setNumber(record.channel.name)
            }
        }
    }, []);
    return <>{number}</>
}

export default DedicatedNumbersShowNumber