import React, {Fragment, useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {Button, Table, Tag, message, Input} from "antd";
import {CopyOutlined, CalendarOutlined} from "@ant-design/icons"
import GET_CHANNELS_FOR_RESERVATIONS from "../../gql/queries/GET_CHANNELS_FOR_RESERVATIONS";
import DedicatedNumbersCreatorModal from "../DedicatedNumbersCreatorReservationModal/DedicatedNumbersCreatorModal.component";


const DedicatedNumbersCreatorReservation = () =>{
    const {data, loading, error, refetch: refetchChannels} = useQuery(GET_CHANNELS_FOR_RESERVATIONS)
    const [dedicatedReservations, setDedicatedReservations] = useState([])
    const [allDedicatedReservations, setAllDedicatedReservations] = useState([])
    const [searchString, setSearchString] = useState("")

    const columns = [
        {
            title:"Numer telefonu",
            dataIndex:"name",
            key:"name"
        },
        {
            title:"Dostępność",
            dataIndex:"availability",
            key:"availability",
            render:(_:any, record:any)=><>
                {record.dedicatedReservations.edges.length? <Tag color="red-inverse">Niedostępny</Tag>:<Tag color="green-inverse">Dostępny</Tag>}
            </>
        },
        {
            title:"Trasa",
            dataIndex:"route",
            key:"route",
            render:(_:any, record:any)=><>
                {record.textRoute&& <Tag color="green-inverse">Text</Tag>}
                {record.mmsRoute&& <Tag color="volcano-inverse">MMS</Tag>}
                {record.voiceFixedRoute&& <Tag color="gold-inverse">Głos Stacjonarne</Tag>}
                {record.voiceMobileRoute&& <Tag color="geekblue-inverse">Głos mobilne</Tag>}
            </>
        },
        {
            title:"Działania",
            dataIndex:"actions",
            key:"action",
            render:(_:any, record:any)=><Fragment key={record.id}>
                <Button type="primary" onClick={()=>{
                    navigator.clipboard.writeText(record.name);
                    message.success("Skopiowano numer do schowka")
                }}><CopyOutlined />Kopiuj</Button>
                {!record.dedicatedReservations.edges.length&& <DedicatedNumbersCreatorModal key={record.id} refetchChannels={refetchChannels} channel_id={record.id} />}
            </Fragment>
        },
    ]

    useEffect(() => {
        if(data){
            setDedicatedReservations(data.salesman.getChannelsForReservations)
            setAllDedicatedReservations(data.salesman.getChannelsForReservations)
        }
    }, [data]);

    useEffect(() => {
        let dedicated_reservations_filtered = allDedicatedReservations.filter((reservation:any)=>{
            let status=false
            return reservation.name.toUpperCase().includes(searchString.toUpperCase())
        })
        setDedicatedReservations(dedicated_reservations_filtered)
    },[searchString])

    return(
        <>
        <Input placeholder={"Wyszukaj numer"} onChange={(e)=>setSearchString(e.target.value)} />
        <Table
            columns={columns}
            dataSource={dedicatedReservations}
            loading={loading}
        />
        </>
    )
}

export default DedicatedNumbersCreatorReservation