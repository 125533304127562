import {gql} from "@apollo/client";

const GET_DEDICATED_RESERVATIONS = gql`
query{
  salesman{
    getDedicatedReservations{
      id,
      status,
      office{
        id,
        name,
        displayName
        shortname,
        dedicatedChannel{
          name,
        }
      },
      channel{
        id,
        name
      }
      start,
      end,
      price,
    }
  }
}
`

export default GET_DEDICATED_RESERVATIONS