import {Breadcrumb, Layout, theme, Collapse} from "antd";
import {useParams} from "react-router";
import React, {useContext, useEffect, useState} from "react";
import {useQuery} from "@apollo/client";

import OfficeEditDetails from "../../components/OfficeEditDetails/OfficeEditDetails.component";
import OfficeChannels from "../../components/OfficeChannels/OfficeChannels.component";

import GET_MY_OFFICE from "../../gql/queries/GET_MY_OFFICE";
import OfficeGroups from "../../components/OfficeGroups/OfficeGroups.component";
import OfficePricelist from "../../components/OfficePricelist/OfficePricelist.component";
import OfficeModules from "../../components/OfficeModules/OfficeModules.component";
import OfficeWelcomeMessages from "../../components/OfficeWelcomeMessages/OfficeWelcomeMessages.component";
import OfficeTopups from "../../components/OfficeTopups/OfficeTopups.component";
import OfficeAgreements from "../../components/OfficeAgreements/OfficeAgreements.component";
import OfficeStatistics from "../../components/OfficeStatistics/OfficeStatistics.component";
import {UserContext} from "../../contexts/UserContext.context";

const { Content } = Layout;

const OfficeEditView: React.FC = () =>{
    const {officeId} = useParams()

    const {data:office_data, loading:office_loading, error:office_error} = useQuery(GET_MY_OFFICE, {variables:{"id":officeId}})
    const [officeBasicData,setOfficeBasicData] = useState({displayName: "", shortname:""})
    const {salesman, setSalesman} = useContext(UserContext)

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const collapse_items = [
        {
            key: 1,
            label: `Podstawowe dane klienta: ${officeBasicData['displayName']} (${officeBasicData['shortname']})`,
            children: <OfficeEditDetails officeId={officeId} />
        },
        {
            key: 2,
            label: `Kanały`,
            children: <OfficeChannels officeId={officeId} />
        },
        {
            key: 3,
            label: `Grupy`,
            children: <OfficeGroups officeId={officeId} />,
        },
        // #TODO: To develop in the future
        // {
        //     key: 4,
        //     label: `Cennik`,
        //     children: <OfficePricelist officeId={officeId} />,
        // },
        {
            key: 5,
            label: `Moduły`,
            children: <OfficeModules officeId={officeId} />
        },
        // #TODO: To develop in the future
        // {
        //     key: 6,
        //     label: `Wiadomości powitalne`,
        //     children: <OfficeWelcomeMessages officeId={officeId} />
        // },
        // {
        //     key: 7,
        //     label: `Doładowania`,
        //     children: <OfficeTopups officeId={officeId} />
        // },
        // {
        //     key: 8,
        //     label: `Zgody`,
        //     children: <OfficeAgreements officeId={officeId} />
        // },
        {
            key: 9,
            label: `Statystyki`,
            children: <OfficeStatistics officeId={officeId} />
        },
    ]
    useEffect(() => {
        if(office_data){
            setOfficeBasicData(office_data.salesman.getMyOffice)
        }
    }, [office_data]);

    useEffect(() => {
    }, [officeBasicData]);

    return(
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Moi klienci</Breadcrumb.Item>
                <Breadcrumb.Item>Edycja klienta</Breadcrumb.Item>
                <Breadcrumb.Item>{officeBasicData['displayName']}</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
                <Collapse items={collapse_items} defaultActiveKey={[1]} />
            </div>
        </Content>
    )
}

export default OfficeEditView